// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LogoIda_EhxlW{cursor:pointer}.LogoIda_EhxlW._black_56Dmk{--color:#17181c}.wrapper_Il01N{align-items:center;display:flex;justify-content:space-between}@media(max-width:767.98px){.wrapper_Il01N{align-items:flex-start;flex-direction:column}}.inner_moekZ{height:4.4rem;padding:1px;position:relative;width:15.1rem}@media(max-width:1279.98px){.inner_moekZ{width:15.4rem;width:144px}}.rectangleBottom_9DN6c,.rectangleTop_YFdIl{left:-1px;overflow:hidden;position:absolute}.rectangleBottom_9DN6c span,.rectangleTop_YFdIl span{background:linear-gradient(to right,var(--color) 50%,#0000 50%) 100%;background-size:200% 100%;height:100%;left:-2px;position:absolute;right:-2px}.rectangleTop_YFdIl{height:50%;top:0;width:39%}.rectangleBottom_9DN6c{bottom:0;height:calc(50% + 1px);width:100%}.logoIcon_t-IXn{color:var(--color);height:100%;transform:translateZ(0);width:100%}.text_Q7d0H{color:#73788c;font-size:1.2rem;font-weight:400;letter-spacing:-.04em;line-height:120%;margin-left:2rem;position:relative}.text_Q7d0H .mobile{display:none}@media(max-width:1279.98px){.text_Q7d0H{margin-left:2rem}}@media(max-width:767.98px){.text_Q7d0H{margin-left:0;margin-top:.2rem}.text_Q7d0H .desktop{display:none}.text_Q7d0H .mobile{display:block}.text_Q7d0H br:first-child{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"LogoIda": "LogoIda_EhxlW",
	"_black": "_black_56Dmk",
	"wrapper": "wrapper_Il01N",
	"inner": "inner_moekZ",
	"rectangleBottom": "rectangleBottom_9DN6c",
	"rectangleTop": "rectangleTop_YFdIl",
	"logoIcon": "logoIcon_t-IXn",
	"text": "text_Q7d0H"
};
module.exports = ___CSS_LOADER_EXPORT___;
