
import gsap from 'gsap';

export default {
    name: 'LogoIda',

    props: {
        color: {
            type: String,
            default: 'black',
            validator: value => [
                'black',
            ].includes(value),
        },

        text: {
            type: String,
            default: 'лучшие <br class="desktop"> цифровые <br class="mobile"> продукты <br class="desktop"> для недвижимости',
        },

        isTouch: {
            type: Boolean,
            default: false,
            required: true,
        },

        href: {
            type: String,
            default: 'https://idaproject.com/',
        },

        to: {
            type: String,
            default: '',
        },

        target: {
            type: String,
            default: '_blank',
        },
    },

    data() {
        return {
            isHoverLogo: false,
        };
    },

    computed: {
        classes() {
            return [{
                [this.$style[`_${this.color}`]]: this.color,
            }];
        },

        component() {
            return this.to ? 'nuxt-link' : 'a';
        },

        getAttrs() {
            const attrs = {};

            if (this.href) {
                attrs.href = this.href;
                attrs.target = this.target;
            } else if (this.to) {
                attrs.to = this.to;
            }

            return attrs;
        },
    },

    methods: {
        onMouseenter() {
            if (this.isHoverLogo || this.isTouch) {
                return;
            }

            this.isHoverLogo = true;

            const animationElements = [this.$refs.rectangleTop, this.$refs.rectangleBottom];
            this.initAnimation(animationElements);
        },

        finishHoverLogo() {
            this.isHoverLogo = false;
        },

        initAnimation(animationElements) {
            if (!animationElements.length) {
                return;
            }

            const tl = gsap.timeline({
                onComplete: () => {
                    this.finishHoverLogo();
                },
            });

            tl
                .set(animationElements, {
                    backgroundPosition: '100%',
                })
                .to(animationElements, {
                    backgroundPosition: '0%',
                    ease: 'power1.inOut',
                    duration: 0.6,
                    stagger: 0,
                })
                .to(animationElements, {
                    backgroundPosition: '-100%',
                    ease: 'power2.inOut',
                    duration: .8,
                    delay: 1,
                    stagger: 0,
                });
        },
    },
};
